import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aristotle from "../../assets/svg/aristotle.svg";
import Team01 from "../../assets/png/team/team-01.png";
import Team02 from "../../assets/png/team/team-02.png";
import Quill from "../../assets/png/quill.png";
import Leg from "../../assets/png/leg.png";
import PlatoHead from "../../assets/png/plato-head-2.png";

import "./about.scss";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="about-us">
      <section className="banner">
        <div className="desktop-max-width">
          <div className="text" data-aos="fade-up-left">
            <img className="aristotle" src={Aristotle} alt="aristotle" />
            Excellence is never an accident. It is the result of high intention,
            sincere effort, and intelligent execution; it represents the wise
            choice of many alternatives.”
          </div>
        </div>
      </section>

      <section className="">
        <div className="desktop-max-width about-row">
          <div className="s-text">
            <div className="s-title" data-aos="fade-up-right">
              Everything to know <span>about us</span>
            </div>

            <div className="s-desc" data-aos="fade-up-right">
              At Ricochet Meliora, we are more than just a consulting firm – we
              are your dedicated partners in unlocking your business's full
              potential. With a profound understanding of the ever-evolving
              business landscape, we bring a strategic approach that fosters
              innovation, drives growth, and propels your organization toward
              transformative success.
            </div>
          </div>

          <div className="img-container head">
            <img src={PlatoHead} alt="Plato Head" data-aos="fade-up-left" />
          </div>
        </div>
      </section>

      <section className="lighter">
        <div className="desktop-max-width about-row">
          <div className="s-text">
            <div className="s-title" data-aos="fade-up-right">
              Our journey
            </div>

            <div className="s-desc" data-aos="fade-up-right">
              Founded on a shared vision to reshape the way businesses thrive,
              Ricochet Meliora emerged from a collective passion for progress.
              Our journey began with a simple yet powerful goal: to elevate
              organizations across industries by providing comprehensive
              consulting solutions that lead to meaningful and lasting change.
            </div>
          </div>

          <div className="img-container">
            <img src={Leg} alt="leg" data-aos="fade-up-left" />
          </div>
        </div>
      </section>

      <section>
        <div className="center">
          <div className="s-title max-md" data-aos="zoom-in">
            Unparalleled expertise
          </div>

          <div className="s-desc max-md" data-aos="zoom-in">
            Our team of seasoned experts boasts a wealth of experience
            accumulated over years of navigating complex challenges and seizing
            unprecedented opportunities. From strategy formulation to
            operational enhancement, our holistic approach stems from a deep
            understanding of various sectors and an unwavering commitment to
            excellence.
          </div>
        </div>

        <div className="divider"></div>

        <div className="center">
          <div className="s-title max-md" data-aos="zoom-in">
            Client-centric approach
          </div>

          <div className="s-desc max-md" data-aos="zoom-in">
            At Ricochet Meliora, your success is our ultimate measure of
            achievement. We pride ourselves on building strong, enduring
            partnerships based on trust, transparency, and tangible results. Our
            focus is not just on short-term gains but on fostering a legacy of
            sustainable growth and prosperity.
          </div>
        </div>

        <div className="divider"></div>

        <div className="center">
          <div className="s-title max-md" data-aos="zoom-in">
            Innovation at heart
          </div>

          <div className="s-desc max-md" data-aos="zoom-in">
            Innovation isn't just a buzzword for us – it's at the core of what
            we do. We're passionate about guiding businesses to embrace change,
            adapt to emerging trends, and harness the power of technology. Our
            proactive approach empowers you to stay ahead of the curve and lead
            in an ever-evolving marketplace.
          </div>
        </div>
      </section>

      <section className="team-s lighter center">
        <div className="s-title max-md" data-aos="flip-up">
          Our Team
        </div>

        <div className="team">
          <div className="member" data-aos="fade-up-right">
            <img src={Team01} alt="Tunde Mabawonku" data-aos="fade-up-right" />

            <div className="m-name">Tunde Mabawonku</div>

            <div className="m-title">Co-Founder/Lead Consultant</div>
          </div>

          <div className="member" data-aos="fade-up-left">
            <img src={Team02} alt="Sola Mabawonku" data-aos="fade-up-left" />

            <div className="m-name">Sola Mabawonku</div>

            <div className="m-title">Co-Founder/Lead Consultant</div>
          </div>
        </div>
      </section>

      <section className="center join-us">
        <img src={Quill} alt="quill" data-aos="flip-up" />

        <div className="s-title max-md" data-aos="flip-up">
          Join us on the journey
        </div>

        <Link to="/contact-us">
          <button className="primary" data-aos="flip-up">
            Contact Us
          </button>
        </Link>
      </section>
    </div>
  );
};

export default About;
