import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./contact.scss";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <div>
      <div className="contact-us">
        <div className="desktop-max-width">
          <div className="c-title center" data-aos="fade-up-right">
            Let us grow your <br /> <span>business</span>
          </div>

          <div className="c-form" data-aos="fade-up-left">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="form-item">
                  <input
                    placeholder="Enter first name"
                    {...register("firstName", { required: true })}
                  />
                  {errors.firstName && <p>First name is required.</p>}
                </div>

                <div className="form-item">
                  <input
                    placeholder="Enter last name"
                    {...register("lastName", { required: true })}
                  />
                  {errors.lastName && <p>Last name is required.</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-item">
                  <input
                    placeholder="Enter business email"
                    {...register("businessEmail", { required: true })}
                  />
                  {errors.businessEmail && <p>Business email is required.</p>}
                </div>

                <div className="form-item">
                  <input
                    placeholder="Enter business name"
                    {...register("businessName", { required: true })}
                  />
                  {errors.businessName && <p>Business name is required.</p>}
                </div>
              </div>

              <div className="form-row">
                <div className="form-item full">
                  <textarea
                    placeholder="Type your message"
                    {...register("message", { required: true })}
                  />
                  {errors.message && <p>Message is required.</p>}
                </div>
              </div>

              <button type="submit" className="primary full-width">
                Send Request
              </button>
            </form>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div className="i-inner">
          <div className="c-group">
            <div className="g-title">Address</div>

            <div className="g-desc">
              Ricochet Meliora Office: <br />
              1234, Oppo Street, Bideford <br />
              United Kingdom. EX12 EW2
            </div>
          </div>

          <div className="c-group">
            <div className="g-title">Phone</div>

            <div className="g-desc">
              <div>
                <a href="tel:+449012345678">+449012345678</a>
              </div>
              <div>
                <a href="tel:+447012345678">+447012345678</a>
              </div>
            </div>
          </div>

          <div className="c-group">
            <div className="g-title">Email</div>

            <div className="g-desc">
              <div>
                <a href="mailto:info@ricochetmeliora.co.uk">
                  info@ricochetmeliora.co.uk
                </a>
              </div>
              <div>
                <a href="mailto: sola@ricochetmeliora.co.uk">
                  sola@ricochetmeliora.co.uk
                </a>
              </div>

              <div>
                <a href="mailto:tunde@ricochetmeliora.co.uk">
                  tunde@ricochetmeliora.co.uk
                </a>
              </div>
            </div>
          </div>

          <div className="c-group">
            <div className="g-title">Work Hours</div>

            <div className="g-desc">
              9:00am - 5:00pm <br />
              Mondays - Fridays
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
