import React from "react";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer center">
      © {new Date().getFullYear()} - Ricochet Meliora Consulting Company Inc.{" "}
      <br />
      All Rights Reserved
    </div>
  );
};

export default Footer;
