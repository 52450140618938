import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Plato from "../../assets/png/plato-head.png";
import Partners from "../../components/Partners/Partners";
import Services from "../../components/Services/Services";
import WhatPeopleSay from "../../components/WhatPeopleSay/WhatPeopleSay";
import Statue from "../../assets/png/angel-statue.png";
import "./landing.scss";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="landing-page">
      <section className="banner">
        <div className="banner-inner desktop-max-width">
          <div className="text-content">
            <div className="c-row-one" data-aos="zoom-in">
              Ricochet Meliora
            </div>

            <div className="c-row-two" data-aos="fade" data-aos-duration="2000">
              At Ricochet Meliora, we believe in the power of transformation.
              Our dedicated team of experts is committed to helping your
              business thrive by unlocking its full potential. Through strategic
              insights, innovative solutions, and unwavering support, we
              navigate challenges and create pathways to success.
            </div>

            <button
              className="primary"
              data-aos="fade"
              data-aos-duration="2000"
            >
              Let’s set you apart
            </button>
          </div>

          <div className="banner-image">
            <img
              src={Plato}
              alt=""
              data-aos="fade-left"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </section>

      <section className="proven-expertise">
        <Partners />

        <div className="section-title center" data-aos="zoom-in">
          Proven expertise
        </div>

        <div className="section-desc center" data-aos="zoom-in">
          Drawing from a reservoir of experience accumulated over numerous years
          within the industry, we have diligently honed and perfected our
          expertise, enabling us to consistently orchestrate outcomes that carry
          profound significance.
        </div>

        <div className="center mt-10">
          <Link to="/about">
            <button className="primary">More about us</button>
          </Link>
        </div>
      </section>

      <section className="center lighter">
        <div className="desktop-max-width services">
          <div className="section-title" data-aos="zoom-in">
            Our Services
          </div>

          <div className="section-desc" data-aos="zoom-in">
            Ricochet Meliora offers a comprehensive suite of strategic
            consulting services designed to catalyze transformative growth for
            businesses across diverse industries. Our expertise encompasses
            strategic planning, operational enhancement, digital transformation,
            organizational agility, risk management, and sustainable practices.
            From envisioning market-disrupting strategies to optimizing
            operational efficiencies, we collaborate closely with clients to
            craft bespoke solutions that drive tangible results.
          </div>

          <Services />
        </div>
      </section>

      <section className="newsletter center">
        <div className="section-title mb-2" data-aos="zoom-in">
          Newsletter
        </div>

        <div className="section-desc" data-aos="zoom-in">
          Stay up to date with our latest news and product.
        </div>

        <div className="input-row" data-aos="flip-up">
          <input type="text" placeholder="Email address" />
          <button className="primary">Subscribe</button>
        </div>

        <div className="divider"></div>

        <div className="section-title" data-aos="flip-up">
          What people say
        </div>
        <WhatPeopleSay />
      </section>

      <section className="contact lighter">
        <div className="c-left">
          <img src={Statue} alt="statue" data-aos="fade-up-right" />
        </div>

        <div className="c-right">
          <div className="c-row-one" data-aos="zoom-in">
            Book a session with us today
          </div>

          <Link to="/contact-us">
            <button className="primary" data-aos="zoom-in">
              Contact Us
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Landing;
