import React, { useState } from "react";
import AOS from "aos";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Landing from "./pages/Landing/Landing";
import Footer from "./components/Footer/Footer";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  AOS.init();

  return (
    <div className="App">
      <Navbar {...{ showMobileMenu, setShowMobileMenu }} />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
