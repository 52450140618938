import { NavLink, Link } from "react-router-dom";
import Twitter from "../../assets/svg/socials/twitter.svg";
import Instagram from "../../assets/svg/socials/instagram.svg";
import Linkedin from "../../assets/svg/socials/linkedin.svg";
import Logo from "../../assets/svg/logo.svg";
import "./navbar.scss";

interface NavbarProps {
  showMobileMenu: boolean;
  setShowMobileMenu: (showMobileMenu: boolean) => void;
}

const Navbar = ({ showMobileMenu, setShowMobileMenu }: NavbarProps) => {
  return (
    <section className="nav-container">
      <div className="wrapper desktop-max-width">
        <div className="click">
          <Link to="/">
            <img height={50} className="logo" src={Logo} alt="Logo" />
          </Link>
        </div>

        <ul className="nav-links">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>

          <li>
            <NavLink to="/about">About</NavLink>
          </li>

          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>

        <div className="nav-socials">
          <div>
            <img className="click" src={Twitter} alt="Twitter" />
          </div>

          <div>
            <img className="click" src={Instagram} alt="Instagram" />
          </div>

          <div>
            <img className="click" src={Linkedin} alt="Linkedin" />
          </div>
        </div>

        <div
          className="hamburger-menu"
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        >
          <div className={`hamburger ${showMobileMenu ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
          className={`mobile-menu ${showMobileMenu ? "" : "off"}`}
        >
          <div
            className={showMobileMenu ? "wrapper-mobile on" : "wrapper-mobile"}
          >
            <ul className="nav-links-mobile">
              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <NavLink to="/">Home</NavLink>
              </li>

              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <NavLink to="/about">About</NavLink>
              </li>

              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <NavLink to="/contact-us">Contact Us</NavLink>
              </li>

              <li
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <div className="nav-socials mobile">
                  <div>
                    <img className="click" src={Twitter} alt="Twitter" />
                  </div>

                  <div>
                    <img className="click" src={Instagram} alt="Instagram" />
                  </div>

                  <div>
                    <img className="click" src={Linkedin} alt="Linkedin" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
