import React from "react";
import Wema from "../../assets/png/partners/wema.png";
import Alat from "../../assets/png/partners/alat.png";
import Emergency from "../../assets/png/partners/emergency.png";
import Dozzia from "../../assets/png/partners/dozzia.png";
import First from "../../assets/png/partners/first.png";
import "./partners.scss";

const Partners = () => {
  return (
    <div className="partners">
      <div className="partners-inner">
        <img src={Wema} alt="wema bank" data-aos="flip-up" />
        <img src={Alat} alt="alat by wema" data-aos="flip-up" />
        <img
          src={Emergency}
          alt="emergency response africa"
          data-aos="flip-up"
        />
        <img src={Dozzia} alt="dozzia systems" data-aos="flip-up" />
        <img src={First} alt="first bank" data-aos="flip-up" />
      </div>
    </div>
  );
};

export default Partners;
