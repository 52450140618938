import React from "react";
import Service1 from "../../assets/png/services/Expenditure management.png";
import Service2 from "../../assets/png/services/Investment Intelligence.png";
import Service3 from "../../assets/png/services/Marketing and sales.png";
import Service4 from "../../assets/png/services/Mergers and acquisitions.png";
import Service5 from "../../assets/png/services/Operations consulting.png";
import Service6 from "../../assets/png/services/Organization management.png";
import Service7 from "../../assets/png/services/Risk management.png";
import Service8 from "../../assets/png/services/Supply chain.png";
import "./services.scss";

const Services = () => {
  return (
    <div className="services-main">
      <div className="items-row" data-aos="fade-up-right">
        <div>
          <img src={Service1} alt="Expenditure management" />
          <div className="desc">Expenditure management</div>
        </div>

        <div>
          <img src={Service8} alt="Supply chain" />
          <div className="desc">Supply chain assistance</div>
        </div>

        <div>
          <img src={Service5} alt="Operations consulting" />
          <div className="desc">Operations consulting</div>
        </div>
      </div>

      <div className="items-row extra-space" data-aos="fade-up-left">
        <div>
          <img src={Service3} alt="Marketing and sales" />
          <div className="desc">Marketing & sales</div>
        </div>

        <div>
          <img src={Service7} alt="Risk management" />
          <div className="desc">Risk management</div>
        </div>
      </div>

      <div className="items-row" data-aos="fade-up-right">
        <div>
          <img src={Service6} alt="Organization management" />
          <div className="desc">Organization management</div>
        </div>

        <div>
          <img src={Service4} alt="ergers and acquisitions" />
          <div className="desc">Mergers and acquisitions</div>
        </div>

        <div>
          <img src={Service2} alt="Investment Intelligence" />
          <div className="desc">Investment Intelligence</div>
        </div>
      </div>
    </div>
  );
};

export default Services;
