import React from "react";
import Solomon from "../../assets/png/testimonials/solomon.png";
import "./what-people-say.scss";

const WhatPeopleSay = () => {
  return (
    <div className="what-people-say">
      <div className="w-left" data-aos="fade-up-right">
        <img src={Solomon} alt="Solomon Ayodele" />

        <div>
          <div className="name">Solomon Ayodele</div>

          <div className="title">
            Innovation Manager, <br />
            Wema Bank
          </div>
        </div>
      </div>

      <div className="w-right" data-aos="fade-up-left">
        <div>“</div>
        Ricochet Meliora has been an invaluable partner in our journey towards
        innovation and transformation. Their deep industry insights and
        strategic prowess have not only propelled us into uncharted territories
        but also redefined our approach to business.
      </div>
    </div>
  );
};

export default WhatPeopleSay;
